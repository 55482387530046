html{
  overscroll-behavior: none;
}
body{
  background-color: #000;
}

#webglContainer{
  position: fixed;
  top:10vh;
  left:0;
  width: 100%;
  height: 80vh;
  z-index: 10;
  z-index:0;
  opacity:0;
  visibility: hidden;
  @media screen and (max-width: 768px){
    top: 10vh;
    height: 60vh;
  }
  canvas{
    width: 100%;
    height: 100%;
  }
}
#entryContent{
  position: relative;
  z-index: 10;
}
.no-bg{
  background-color: unset !important;
}
main{
  &[data-barba-namespace='player']{
    pointer-events: none;
    .lower-div > *, .menu-button, .icon-player{
      pointer-events: all;
    }
    .section.player{
      background-color: unset;
    }
    .section.player.postcard{
      display: flex;
      padding-bottom: 0;
      overflow: visible;
    }
    
    

    .makePostcardBlock, .ready-postcard{
      opacity:0;
      visibility: hidden;
    }
    .ready-postcard{
      display: none; 
    }
    .lower-div{
      &.postcard{
        .ready-postcard{
          opacity:1;
          visibility: visible;
          display: flex;
        }
        .makePostcardBlock{
          display: none !important;
        }
      }
      &.makePostcard{
        .makePostcardBlock{
          opacity:1;
          visibility: visible;
        }
      }
    } 
  }
  &[data-barba-namespace='home'],
  &[data-barba-namespace='make-postcard']{
    .section{
      position: relative;
      z-index: 10;
      min-height: 90vh;
      background-image: none;
      background-color: transparent;
    }
  }
}
.make-postcard{
  visibility: hidden;
  @media screen and (max-width: 768px){
    display: none;
  }
}
.playback-tracker{
  overflow: hidden;
  border-radius: 4px;
}
.playback-amount{
  width: 100%;
  right: 100%;
  left: auto;
}

#mute{
  position: relative;
  &.-mute{
   .sound-off{
     opacity:1;
     visibility: visible;
   };
   .sound-on{
     opacity:0;
     visibility: hidden;
   }
  }
  .sound-on,.pause{
    transition: transform .15s ease-out, opacity .15s ease-out, visiblity .15s ease-out;
  }
  .sound-off{
    position: absolute;
    top:0;
    left:0;
    opacity:0;
    visibility: hidden;
    display: block !important;
  }
  .sound-on{
    display: block !important;
    opacity:1;
    visibility: visible;
  }
}
#playPause{
  position: relative;
  &.-play{
   .pause{
     opacity:1;
     visibility: visible;
   };
   .play{
     opacity:0;
     visibility: hidden;
   }
  }
  .play,.pause{
    display: block !important;
    transition: transform .15s ease-out, opacity .15s ease-out, visiblity .15s ease-out;
  }
  .pause{
    position: absolute;
    top:0;
    left:0;
    opacity:0;
    visibility: hidden;
  }
  .play{
    display: block;
    opacity:1;
    visibility: visible;
  }
}
.menu-button{
  transition: all .3s ease-out;
}
.homeBg{
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100vh;
  background-image: url('../assets/images/particles-bright.jpg');
  background-position: 50% 50%;
  background-size: cover;
  z-index:0;
}
.homeContainer{
  position: relative;
  z-index: 10;
}
.header{
  z-index: 100;
  pointer-events: none;
  background-color: transparent;
  &>*{
    pointer-events: auto;
  }
}
.nav{
  top: -10vh;
}
.player-wrap{
  width: 400px;
  max-width: 100%;
}

.social-block .h3{
  text-decoration: none;
}

.icon-player.forward.sound-on


[disabled]{
  pointer-events: none;
}